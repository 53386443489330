import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Heading from './Heading.js'
import Title from './Title.js'
import LowerTable from './LowerTable.js'
import TeamTable from './TeamTable.js';
import './style/heading.css'

export default function HomePage(props){
//cfe8fc bg
//console.log(props)
    return(
        <Container sx={{padding:0}}>
            <Title/>
            <Box sx={{ height: '200vh',overflowX:'scroll' }} >
                <TeamTable teams={props.teams}/>
            </Box>
        </Container>
    )
}
import { faker } from '@faker-js/faker';


var data = []
for(let i=0;i<=99;i++){
    data.push(
        {
            team: faker.address.cityName(),
            offensePoints: faker.datatype.number(100),
            defensePoints: faker.datatype.number(100),
            rank: i + 1
        }
    )
}

export default data

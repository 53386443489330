

export default function TableRow(props){
    console.log(props)
    return(
        <tr>
            <th className="row">{props.team.name}</th>
            <td>{props.team.rank}</td>
            <td>{props.team.conference}</td>
            <td>{props.team.winloss}</td>
        </tr>
    )
}
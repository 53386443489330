import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from './TableRow';
import TableHeading from './TableHeading'
import Box from '@mui/material/Box'
// import Box from "@mui/material/Box"
import data from '../data/testdata';
import './style/Table.css'

export default function TeamTable(props){
    //const [row, setRow] = React.useState(1)
    var row = -1;
    console.log(props.teams)
    if(props.teams){
        return(
            <div id="wrap">
                <table>
                    <caption>NCAA College Basketball Rankings</caption>
                    
                    <tbody>
                    {
                        props.teams.map((team) =>{
                            row++
                            if(row % 10 == 0){
                                return(
                                    <React.Fragment>
                                        <TableHeading/>
                                        <TableRow team={team}/>
                                    </React.Fragment>
                                )
                            }else{
                                return(
                                    <TableRow team={team}/>
                                )
                            }

                        })
                    }
                    </tbody>
                </table>

            </div>
        )
    }else{
        return "Loading"
    }
}
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Box from "@mui/material/Box"
import './style/Table.css'
import Heading from './Heading';
export default function TableHeading(){


    return(
        <tr>
            <th scope="col" className="topLeft">Team</th>
            <th className="top">Rank</th>
            <th className="top">Conference</th>
            <th className="top">W/L</th>

        </tr>

    )
}
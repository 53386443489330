import HomePage from './components/HomePage.js'
import {getTeams, get2} from './api/getTeams'
import React, { useState, useEffect } from 'react';

function App() {
  //let teams = getTeams()
  const [teams,setTeams] = useState()

  useEffect(()=>{
    fetch('https://ugfzihbop32vpcyyw4cg4qiakm0tnwzr.lambda-url.us-east-1.on.aws/')
      .then((response) => response.json())
      .then(data => setTeams(data.teams))
  },[])
  
  return (
    <div className="App">
      <HomePage teams={teams}/>
    </div>
  );
}


export default App;
